<template>  
  <v-container>
    <v-divider></v-divider>
    <v-card class="shadowCard" v-if="!dialogCarga">
      <v-tabs
        dense
        class="mt-4"
        v-model="tab"
        background-color="white"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
          v-for="(item,i) in items"
          :key="i"
        >
          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
        </v-tab>
      </v-tabs>
    </v-card>

    <v-row v-if="!dialogCarga">
      <v-col cols="12">
        <v-tabs-items v-model="tab" class="transparent">
          <!-- General -->
          <v-tab-item>
            <v-col cols="12" lg="8">
              <v-card class="shadowCard">
                <v-card-title primary-title class="pt-0">
                  Editar Artículo
                  <v-spacer></v-spacer>
                  <v-btn 
                    small
                    class="ma-2" 
                    color="secondary" 
                    :to="{name:'catproductos'}" 
                    dark>Atras
                  </v-btn>
                  <!-- BTN GUARDAR -->
                  <v-btn 
                    small 
                    dark 
                    class="ma-2" 
                    @click.prevent="update"
                    color="primary">Grabar
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    readonly
                    label="Código"
                    v-model="articulo.codigo"
                    validate-on-blur
                    class="pa-0"  
                    dense
                    outlined
                  ></v-text-field>

                  <v-switch 
                    v-model="articulo.estatus" 
                    label="Activo en tienda" 
                  ></v-switch>
                  
                  <v-text-field
                    label="Nombre del artículo"
                    v-model="articulo.nomart"
                    outlined 
                    dense
                  ></v-text-field>


                  <v-autocomplete 
                    dense
                    outlined
                    :items="familias"
                    v-model="familia"
                    item-text="familia"
                    item-value="idfamilia" 
                    label="Familia"
                    persistent-hint
                    hide-details
                    return-object
                    clearable
                    :append-outer-icon="familia ? 'mdi-plus-circle' : ''"
                    @click:append-outer="addFamilia"
                  ></v-autocomplete>
                  <v-row class="mb-5">
                    <v-col>
                      <v-chip
                        class="mr-2 mt-2" 
                        v-for="(familia, i) in familiaDelArticulo"
                        :key="i"
                        color="primary"
                        close
                        @click:close="eliminarFamilia(familia.idfamilias_art)"
                      >{{ familia.familia }}</v-chip>
                    </v-col>
                  </v-row>

                  <v-autocomplete 
                    dense
                    outlined
                    :items="categorias"
                    v-model="categoria"
                    item-text="categoria"
                    item-value="idcategorias" 
                    label="Categoria"
                    persistent-hint
                    return-object
                    hide-details
                    :append-outer-icon="categoria ? 'mdi-plus-circle' : ''"
                    @click:append-outer="addCategoria"
                  ></v-autocomplete>
                  <v-row class="mb-5">
                    <v-col>
                      <v-chip
                        class="mr-2 mt-2"  
                        v-for="(categoria, i) in categoriaDelArticulo"
                        :key="i"
                        color="primary"
                        close
                        @click:close="eliminarCategoria(categoria.idcategorias_art)"
                      >{{ categoria.categoria }}</v-chip>
                    </v-col>
                  </v-row>

                  <v-autocomplete 
                    dense
                    outlined
                    :items="subcategorias"
                    v-model="subcategoria"
                    item-text="subcategoria"
                    item-value="idsubcategorias" 
                    label="Subcategoria"
                    persistent-hint
                    return-object
                    hide-details
                    :append-outer-icon="subcategoria ? 'mdi-plus-circle' : ''"
                    @click:append-outer="addsubCategoria"
                  ></v-autocomplete>
                  <v-row class="mb-5">
                    <v-col>
                      <v-chip
                        class="mr-2 mt-2"  
                        v-for="(subcategoria, i) in subcategoriaDelArticulo"
                        :key="i"
                        color="primary"
                        close
                        @click:close="eliminarsubCategoria(subcategoria.idsubcategorias_art)"
                      >{{ subcategoria.subcategoria }}</v-chip>
                    </v-col>
                  </v-row>

                  <v-textarea
                    label="Descripción corta"
                    v-model="articulo.descrip"
                    outlined 
                    auto-grow
                  >
                  </v-textarea>

                  <v-textarea
                    label="Descripción Larga"
                    v-model="articulo.descripLarga"
                    outlined 
                    auto-grow
                  >
                  </v-textarea>
                    
                  <v-alert type="error" :value="alertaInput">
                    {{alerta}}
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-col>
          </v-tab-item>

          <!-- Imágenes -->
          <v-tab-item>
            <v-col cols="12" lg="8">
              <v-card class="shadowCard">
                <v-card-title primary-title>
                  Sube tus imagenes masivamente
                  <v-spacer></v-spacer>
                  <v-btn 
                    small
                    class="ma-2" 
                    color="secondary" 
                    :to="{name:'catproductos'}" 
                    dark>Atras
                  </v-btn>
                  <v-btn color="primary" small @click="subirFotos()">Subir Fotos</v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-file-input
                    outlined
                    dense
                    small-chips
                    multiple
                    v-model="files"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Selecciona tus imagenes"
                    prepend-icon="mdi-camera"
                    label="Imágenes"
                    @change="cargarArchivo()"
                  ></v-file-input>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="5" xs="6" v-for="(img, i) in vistaPrevias" :key="i">
                      <v-card class="py-2">
                        <v-img :src="img.url" contain aspect-ratio="2">
                          <v-row align="end" class="lightbox white--text pa-2 fill-height">
                            <v-col align="right">
                              <v-btn color="error" samll @click="eliminarFoto(img.url)" icon><v-icon>mdi-delete</v-icon></v-btn>
                            </v-col>
                          </v-row>
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title primary-title>
                  Imágenes actuales
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="5" xs="6" v-for="(f, i) in fotos" :key="i">
                      <v-card height="100%">
                        <v-img :src="url + f.image_name" contain height="150"/>
                        <v-card-actions>
                          <v-btn icon color="error" @click="deleteFoto(f.idfotos)"><v-icon>mdi-delete</v-icon></v-btn>
                          <v-spacer></v-spacer>
                          <v-checkbox label="Principal" v-model="f.principal" @click="updateEestatus(f.idfotos)"></v-checkbox>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-tab-item>

          <!-- Precios -->
          <v-tab-item>
            <v-col cols="12" lg="8">
              <v-card class="shadowCard">
                <v-card-title primary-title>
                  Precio del artículo 
                  <v-spacer></v-spacer>
                  <v-btn 
                      small
                      class="ma-2" 
                      color="secondary" 
                      :to="{name:'catproductos'}" 
                      dark>Atras
                    </v-btn>
                  <v-btn color="primary" @click="update" small>Actualizar</v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        label="Precio compra"
                        v-model = "articulo.preciocompra"
                        type="number"
                        prefix="$"
                        suffix="MXN"
                        outlined
                        dense
                      ></v-text-field>

                      <v-text-field
                        label="Precio venta"
                        v-model = "articulo.precio1"
                        type="number"
                        prefix="$"
                        suffix="MXN"
                        outlined
                        dense
                      ></v-text-field>

                      <v-text-field
                        label="% Descuento"
                        v-model = "articulo.pjedesc"
                        type="number"
                        prefix="%"
                        @change="calcularDescuento()"
                        outlined
                        dense
                      ></v-text-field>

                      <v-text-field
                        v-model="preciowithdescuento"
                        label="Precio con descuento"
                        prefix="$"
                        suffix="MXN"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-tab-item>

          <!-- Barcode -->
          <v-tab-item>
            <v-col cols="12" lg="8">
              <v-card class="shadowCard">
                <v-card-title primary-title>
                  Barcode del artículo
                  <v-spacer></v-spacer>
                  <v-btn 
                    small
                    class="ma-2" 
                    color="secondary" 
                    :to="{name:'catproductos'}" 
                    dark>Atras
                  </v-btn>
                </v-card-title>
                
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="6">
                      <v-card align="center" class="elevation-0">
                        <VueBarcode :value="articulo.codigo" :options="barcode_option" tag="svg" class="mb-0 pb-0"></VueBarcode>
                        <v-card-actions class="pt-0 mt-0">
                          <v-spacer></v-spacer>
                          <span>{{articulo.nomart}}</span>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
             
    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-container>
</template>

<script>
import axios from 'axios';
  import {mapGetters} from 'vuex'
  import VueBarcode from '@xkeshi/vue-barcode';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'
  export default {

    components:{
      Alerta,
      carga,
      VueBarcode
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      dialogCarga:false,
      id: 0,
      statusweb: false,
      producto:{},
      articulo:{},

      tab: {name:'Información'   , icon:'mdi-file' }, 
      items: [
        {name:'Información'      , icon:'mdi-file' }, 
        {name:'Imágemes'         , icon:'mdi-file-image-box' }, 
        {name:'Precios'          , icon:'mdi-currency-usd' }, 
        {name:' Barcode'         , icon:'mdi-barcode' }, 
      ],

      familia:null,

      existe: true,

      snackbar: false,
      text:'',
      color:'',

      codigo:'',
      result2:'',
      nomart:'',
      sal:'',
      lab:'',

      alerta:'',
      alertaInput: false,

      categorias:[],
      categoria:null,

      familias:[],
      familia:null,

      subcategorias:[],
      subcategoria:null,

      preciowithdescuento:0,

      url:'',
      fotos:[],
      rules: [
        value => !value ,
      ],
      files: [],
      vistaPrevias:[],

      familiaDelArticulo:[],
      categoriaDelArticulo:[],
      subcategoriaDelArticulo:[],
      barcode_option:{
        DisplayValue: true, // Si se muestran los datos del código de barras de forma predeterminada
        Fondo: '#fff', // Color de fondo del código de barras
        valid: function (valid) {
          console.log(valid)
        },
        Ancho: '1px', // El ancho de un solo código de barras
        height: '55px',
        FontSize: '22px' // tamaño de fuente
      },
       
      barcodes:'A01-01-01'
    }),


    computed: {
      ...mapGetters('productos',['getProducto']),
      validaInfo(){
        return  this.nomart      !==  '' && 
                this.sal         !==  '' &&       
                this.laboratorio.idlaboratorios         !==  '' 
      },
    },

    watch:{
      familia () {
        this.categorias = []
        if(this.familia){
          this.$http.get('categorias.familia/'+ this.familia.idfamilias).then(response=>{
            this.categorias = response.data
          }).catch(error=>{
            this.existe = false
            console.log('error',error)
          })
        }
      },

      categoria () {
        this.subcategorias = []
        if(this.categoria){
          this.$http.get('subcategorias.categoria/'+ this.categoria.idcategorias).then(response=>{
            this.subcategorias = response.data
          }).catch(error=>{
            this.existe = false
            console.log('error',error)
          })
        }
      },

    },

    async created(){
      this.dialogCarga = true
      await this.init()
      await this.consultarFamilias()
      await this.cargarArticulo()
      await this.cargarFotosArt()
      await this.categoriasArt()
      await this.familiasArt()
      await this.subcategoriasArt()
      this.dialogCarga = false
    },

    methods:{

      cargarArticulo () {
        return this.$http.get('articulos.id/' + this.id).then(response=>{
          this.articulo = response.data
        }).catch(error=>{
          this.abrirAlerta(error.body.message,'error')
        })
      },

      cargarFotosArt () {
        // Cargar las fotos
        return this.$http.get('articulos.fotos/' + this.articulo.codigo).then(response=>{
          if(response.data.length > 0){
            this.fotos = response.data
            this.fotos.forEach((element)=>{
              element.principal = !! +element.principal
            })
          }
        }).catch(error=>{console.log(error)})
      },

      familiasArt () {
        return this.$http.get("familias.art.id/" + this.id).then(response=>{
          this.familiaDelArticulo = response.data
        }).catch(error=>{console.log(error)})
      },

      categoriasArt () {
        return this.$http.get("categorias.art.id/" + this.id).then(response=>{
          this.categoriaDelArticulo = response.data
        }).catch(error=>{console.log(error)})
      },

      subcategoriasArt () {
        return this.$http.get("subcategorias.art.id/" + this.id).then(response=>{
          this.subcategoriaDelArticulo = response.data
        }).catch(error=>{console.log(error)})
      },

      init () {
        this.url = axios.defaults.baseURL + 'imagenes-tienda/'
        this.fotos = this.getProducto.fotos
        this.codigo = this.getProducto.codigo
        this.id = this.getProducto.id
        return 
      },

      consultarFamilias () {
        return this.$http.get('familias.activo').then(response=>{
          this.familias = response.data
        }).catch(error=>{
          console.log('error',error)
        })
      },

      update(){
        if(this.familia){      this.articulo.idfamilias =      this.familia.idfamilias}
        if(this.categoria){    this.articulo.idcategorias =    this.categoria.idcategorias}
        if(this.subcategoria){ this.articulo.idsubcategorias = this.subcategoria.idcategorias}

        if(this.articulo.estatus == true){
          this.articulo.estatus = 1
        }else{
          this.articulo.estatus = 0
        }

        this.$http.put('articulos.update/'+ this.id, this.articulo).then(response=>{
          this.validarSuccess('Artículo actualizado correctamente')
        }).catch(error=>{
          this.abrirAlerta(error.body.message,'error')
        })
      },

      calcularDescuento(){
        if(this.articulo.pjedesc > 0){
          this.preciowithdescuento = ((100 - this.articulo.pjedesc)/100) * this.articulo.precio1
        }else{
          this.preciowithdescuento = 0
        }
      },

      cargarFotos(){
        var url2 = axios.defaults.baseURL + 'fotos/'
        this.url = url2
        this.fotos = []
        this.$http.get('articulos.fotos/' + this.articulo.codigo).then(response=>{
          if(response.data.length > 0){
            this.fotos = response.data
            this.fotos.forEach((element)=>{
              element.principal = !! +element.principal
            })
          }
        }).catch(error=>{console.log(error)})
      },

      cargarArchivo(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name
          })
        };
      },

      subirFotos(){
        // Recorremos las imagenes previas cargadas para poder agregarlas al servidor y guardar el nombre en la base de datos
        this.vistaPrevias.forEach((element, index) => {
          var payload = {
            codigo: this.articulo.codigo,
            image_name: element.image_name,
            principal: 0
          }
          this.$http.post('fotos.add', payload).then(response=>{
            // Mandamos el formdata (archivo de la imagen) al servidor
            this.$http.post('imagenes', element.formData).then(response=>{
              //  y se gguar tambien en la base de datos, solo se guarda el nombre de la imagen
              if(index == 0){
                this.text  = 'Artículo actualizado'
                this.snackbar = true
                this.color = 'success'
                // Limpiamos las imagenes previas ya cuando se haya terminado el recorrido del foreach
                this.vistaPrevias = []
                this.files = []
                this.cargarFotos()
              }
            }).catch(error=>{
              this.abrirAlerta(error.body.message,'error')
            })
          }).catch(error=>{
            this.abrirAlerta(error.body.message,'error')
          })
        })
      },

      abrirAlerta (mensaje,color) {
        this.text  = mensaje
        this.color = color
        this.snackbar = true
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      deleteFoto(value){
        this.$http.delete('admin/foto.art.delete/' + value).then(response=>{
          this.cargarFotos()
        }).catch(error=>{console.log(error)})
      },

      updateEestatus(id){
        this.fotos.forEach((element,index)=>{
          if(element.idfotos == id){
            element.principal = 1
          }else{
            element.principal = 0
          }
          this.$http.put('update.foto.principal/' + element.idfotos, element).then(response=>{
          }).catch(error=>{console.log(error)})
          if(index == this.fotos.length - 1){
            this.cargarFotos()
          }
        })
      },

      eliminarFamilia (val) {
        this.$http.delete("familias.art.delete/" + val).then(response=>{
          this.familiasArt().then((result)=>{
            this.text  = 'Familia eliminada'
            this.snackbar = true
            this.color = 'success'
          })
        }).catch(error=>{console.log(error)})
      },

      addFamilia () {
        const existenciaFamilia = this.familiaDelArticulo.find(articulo => articulo.idfamilias == this.familia.idfamilias)
        if(existenciaFamilia){
          this.validarErrorDirecto('Familia ya existe')

        }else{
          var payload = {
            idfamilias: this.familia.idfamilias,
            idarticulo: this.id 
          }
          this.$http.post("familias.art.add",payload).then(response=>{
            this.familiasArt().then((result)=>{
              this.validarSuccess('Familia agregada correctamente')
            })
          }).catch(error=>{console.log(error)})
        }
      },

      addCategoria () {
        const existenciacategoria = this.categoriaDelArticulo.find(articulo => articulo.idcategorias == this.categoria.idcategorias)
        if(existenciacategoria){
          this.validarErrorDirecto('categoria ya existe')
        }else{
          var payload = {
            idcategorias: this.categoria.idcategorias,
            idarticulo: this.id 
          }
          this.$http.post("categorias.art.add",payload).then(response=>{
            this.categoriasArt().then((result)=>{
              this.validarSuccess('Categoría agregada correctamente')
              this.text  = 'categoria agregada'
              this.snackbar = true
              this.color = 'success'
            })
          }).catch(error=>{console.log(error)})
        }
      },

      eliminarCategoria (val) {
        this.$http.delete("categorias.art.delete/" + val).then(response=>{
          this.categoriasArt().then((result)=>{
            this.text  = 'categoria eliminada'
            this.snackbar = true
            this.color = 'success'
          })
        }).catch(error=>{console.log(error)})
      },

      addsubCategoria () {
        const existenciacategoria = this.categoriaDelArticulo.find(articulo => articulo.idcategorias == this.categoria.idcategorias)
        if(existenciacategoria){
          this.validarErrorDirecto('categoria ya existe')
        }else{
          var payload = {
            idsubcategorias: this.subcategoria.idsubcategorias,
            idarticulo: this.id 
          }
          this.$http.post("subcategorias.art.add",payload).then(response=>{
            this.subcategoriasArt().then((result)=>{
              this.validarSuccess('Categoría agregada correctamente')
            })
          }).catch(error=>{console.log(error)})
        }
      },
      
      eliminarsubCategoria (val) {
        this.$http.delete("subcategorias.art.delete/" + val).then(response=>{
          this.subcategoriasArt().then((result)=>{
            this.text  = 'categoria eliminada'
            this.snackbar = true
            this.color = 'success'
          })
        }).catch(error=>{console.log(error)})
      },
    }

  }

</script>